import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export type LoginAuthData = {
    access: string;
    refresh: string;
    name?: string;
}

export type RefreshAuthData = {
    access: string;
}

export type AuthCredentials = {
    username: string;
    password: string;
}

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_API_URL,
    }),
    endpoints: (builder) => ({
        login: builder.mutation<LoginAuthData, AuthCredentials>({
            query: (body) => ({
                url: 'token/',
                method: 'POST',
                body,
            }),
        }),
        refresh: builder.mutation<RefreshAuthData, string>({
            query: (refresh) => ({
                url: 'token/refresh/',
                method: 'POST',
                body: {refresh},
            }),
        })
    })
});

export const {
    useLoginMutation,
    useRefreshMutation,
} = authApi;