import Header from "@/layout/Header.tsx";
import {Button} from "@/components/ui/button.tsx";
import {addValidateTicket, clearCurrentClient, setValidateTickets} from "@/stores/currentClientSlice.ts";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@/stores";
import {useEffect, useMemo, useState} from "react";
import collect from "collect.js";
import {useNavigate} from "react-router-dom";
import {ClientTicketsState, useGetAllClientTicketsQuery, useValidateTicketMutation} from "@/stores/api/tickets.ts";
import {CardStackIcon} from "@radix-ui/react-icons";
import {Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger} from "@/components/ui/dialog.tsx";


export default function Validate() {

    const {validateTickets, clientData} = useSelector((state: RootState) => state.currentClient);

    const navigate = useNavigate();

    useEffect(() => {
        if(!clientData) {
            navigate('/identification');
        }
        setAllBalance();
    }, [clientData, navigate]);

    const dispatch = useDispatch();
    function setAllBalance() {
        if(!clientData) return;
        dispatch(
            setValidateTickets(
                clientData.cards.map(ticket => ({
                    ticket_type_id: ticket.ticket_type_id,
                    use: ticket.balance,
                    name: ticket.ticket_type_name
                }))
            )
        );
    }

    if(!clientData) {
        return null;
    }

    return (
        <>
            <Header/>

            <div className="pt-8 flex flex-col w-[95vw] max-w-md mx-auto items-stretch relative">
                <ShowAllUserCards
                    clientId={clientData.ticket_user}
                />
                <div className="text-center flex justify-center flex-col items-center">
                    <h2 className="text-2xl font-bold">שלום, {clientData.user_data?.name ?? 'ללא שם'}</h2>
                    {clientData.user_data?.phone &&
                        <h3 className="text-xl">{clientData.user_data.phone}</h3>
                    }
                </div>
                <div className="mt-8 text-center">
                   <Button
                       className="bg-dark-blue text-white text-lg font-bold hover:bg-dark-blue/80"
                       onClick={() => setAllBalance()}
                   >
                       בחר את כל הכרטיסים
                   </Button>
                </div>
                <div className="mt-10">
                    {clientData.cards.map(ticket => (
                        <ValidateItemTicket key={ticket.ticket_type_id} ticket={ticket}/>
                    ))}
                </div>

                <div className="mt-10 flex flex-col items-center text-center">
                    <div className="text-3xl font-black">
                        {collect(validateTickets).sum('use')} כרטיסים נבחרו
                    </div>
                    <div>
                        מתוך {collect(clientData.cards).sum('balance')} כרטיסים
                    </div>
                </div>

                <div className="mt-10 w-full flex flex-col gap-4 items-center px-20">
                    <ValidateAction/>
                    <Button variant="link" className="opacity-50"
                            onClick={() => dispatch(clearCurrentClient())}
                    >
                        ביטול ומעבר לזיהוי חדש
                    </Button>
                </div>
            </div>
        </>
    )
}

function ValidateItemTicket({ticket}: {ticket: ClientTicketsState['data']['cards'][0]}) {

    const dispatch = useDispatch();

    const validateCount = useSelector((state: RootState) => state.currentClient
        .validateTickets
        .find(t => t.ticket_type_id === ticket.ticket_type_id)?.use ?? 0);
    const addTicket = () => {
        if(validateCount < ticket.balance) {
            dispatch(addValidateTicket({
                ticket_type_id: ticket.ticket_type_id,
                use: validateCount + 1,
                name: ticket.ticket_type_name
            }));
        }
    }

    const currentBalance = useMemo(() => ticket.balance - validateCount, [ticket.balance, validateCount]);
    const removeTicket = () => {
        if(validateCount > 0) {
            dispatch(addValidateTicket({
                ticket_type_id: ticket.ticket_type_id,
                use: validateCount - 1,
                name: ticket.ticket_type_name
            }));
        }
    }

    return (
        <div className="bg-white rounded-lg shadow-md p-4 mt-4 flex justify-between items-center">
            <div>
                <h3 className="text-lg font-bold">{ticket.ticket_type_name}</h3>
                <div>יתרה: {currentBalance ?? 0}</div>
            </div>
            <div className="flex items-center gap-4">
                <Button size="icon" className="select-none" disabled={!currentBalance} onClick={addTicket}>+</Button>
                <span className="text-2xl font-bold">{validateCount}</span>
                <Button size="icon" className="select-none" disabled={validateCount === 0} onClick={removeTicket}>-</Button>
            </div>
        </div>
    )
}

function ValidateAction() {

    const [validate, { isLoading}] = useValidateTicketMutation();

    const [error, setError] = useState<string | null>(null);

    const {validateTickets, clientData} = useSelector((state: RootState) => state.currentClient);

    const navigate = useNavigate();

    const currentEvent = useSelector((state: RootState) => state.currentEvent.value);
    const dispatch = useDispatch();
    function validateHandler() {
        if(isLoading || !clientData || !currentEvent) return;

        validate({
            event_id: currentEvent.id,
            ticket_user: clientData.ticket_user,
            identification: clientData.identification,
            value: clientData.value,
            cards: validateTickets.map(ticket => ({
                ticket_type_id: ticket.ticket_type_id,
                use: ticket.use,
            }))
        }).unwrap()
            .then((res) => {
                dispatch(setValidateTickets(validateTickets.map(ticket => ({
                    ...ticket,
                    use: res.data.cards.find((c) => c.ticket_type_id === ticket.ticket_type_id)?.use ?? 0,
                }))));
                dispatch(clearCurrentClient());
                navigate('/identification')
                // navigate('/summary')
            }).catch((res) => {
                setError(res.data.error_msg ?? 'אירעה שגיאה');
            });
    }

    return (
        <>
            <Button
                onClick={validateHandler}
                disabled={isLoading}
                className="block w-full h-16 text-2xl font-bold border border-black"
            >
                תיקוף
            </Button>
            {error && <div className="text-red-500 text-center text-sm">{error}</div>}
        </>
    )
}

type ShowAllUserCardsProps = {
    clientId: number;
}
function ShowAllUserCards(props: ShowAllUserCardsProps) {

    const [open, setOpen] = useState(false);

    const {clientId} = props;

    const {data, isLoading, refetch} = useGetAllClientTicketsQuery({clientId}, {
        skip: !open,
        refetchOnMountOrArgChange: 3
    });

    return (
        <Dialog
            open={open}
            onOpenChange={setOpen}
        >
            <DialogTrigger asChild>
                <Button variant="secondary" size="sm" className="absolute -top-4 end-0">
                    <CardStackIcon className="h-6 w-6"/>
                    <span className="ms-1">
                        הצג כרטיסי לקוח
                    </span>
                </Button>
            </DialogTrigger>
            <DialogContent className="bg-white h-full w-full">
                {isLoading &&
                    <div className="flex flex-col gap-4 p-full w-full h-full justify-center items-center">
                    <span
                        className="w-20 h-20 rounded-full border-4 border-dark-blue block border-t-transparent animate-spin"
                    />
                        <span className="text-lg font-bold text-dark-blue">
                        טוען...
                    </span>
                    </div>
                }

                {data && (
                    <div>
                        <DialogHeader>
                            <DialogTitle>כרטיסי לקוח: {data.data.user_data.name}
                                <Button variant="link" onClick={refetch}>רענון</Button>
                            </DialogTitle>
                        </DialogHeader>
                        <div className="mt-8">
                            {data.data.cards.map((card, index) => (
                                <div key={index} className="border-b py-1 flex justify-between">
                                    <div>
                                        <div className="text-lg font-bold">{card.event_name}</div>
                                        <div>{card.event_date}</div>
                                    </div>

                                    <div className="flex flex-col items-end">
                                        <div className="font-bold text-lg">{card.ticket_type_name}</div>
                                        <div>נותרו {card.balance} מתוך {card.quantity}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    )
}