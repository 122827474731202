import {createContext, PropsWithChildren, useEffect, useState} from 'react'
import {jwtDecode} from 'jwt-decode';
import {useNavigate} from 'react-router-dom'
import {AuthData} from "@/types";
import {AuthCredentials, useLoginMutation, useRefreshMutation} from "@/stores/api/auth.ts";
// import {useDispatch} from "react-redux";

const AuthContext = createContext<AuthData>({} as AuthData);

export default AuthContext;

export const AuthProvider = ({children}: PropsWithChildren) => {

    const [user, setUser] = useState(() => (localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens') ?? '') : null))
    const [authTokens, setAuthTokens] = useState(() => (localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens') ?? '{}') : null))
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string|null>(null);

    const navigate = useNavigate();

    // const dispatch = useDispatch();

    const [login, {isLoading: isLoginProcess}] = useLoginMutation();
    const [refresh] = useRefreshMutation();

    const loginUser = async (credentials: AuthCredentials) => {
        login(credentials)
            .unwrap()
            .then((data) => {
                data = {...data, name: credentials.username}
                localStorage.setItem('authTokens', JSON.stringify(data));
                setAuthTokens(data)
                setUser(jwtDecode(data?.access))
                navigate('/events')
                setError(null)
            }).catch((err) => {
            console.log(err)
                switch (err.status) {
                    case 401:
                        setError('שם משתמש או סיסמה שגויים')
                        break;
                    case 400:
                        setError('שדות חובה חסרים')
                        break;
                    default:
                        setError('שגיאת שרת')
                }
            });
    }

    const logoutUser = () => {
        localStorage.removeItem('authTokens')
        setAuthTokens(null)
        setUser(null)
        // dispatch(dataApi.util.resetApiState());
        navigate('/login')
    }

    const updateToken = () => {
        if(!authTokens?.refresh){
            logoutUser();
        } else {
            refresh(authTokens?.refresh).unwrap()
                .then((data) => {
                    const newTokens = {
                        ...authTokens,
                        ...data,
                    };
                    setAuthTokens(newTokens)
                    setUser(jwtDecode(newTokens.access))
                    localStorage.setItem('authTokens',JSON.stringify(newTokens))
                }).catch(() => {
                logoutUser()
            });
        }

        if(loading){
            setLoading(false)
        }
    }

    const contextData: AuthData = {
        user:user,
        authTokens:authTokens,
        loginUser:loginUser,
        logoutUser:logoutUser,
        userName: authTokens?.name,
        isLoginProcess: isLoginProcess,
        error: error,
    }

    useEffect(()=>{
        if(loading){
            updateToken()
        }

        const REFRESH_INTERVAL = import.meta.env.VITE_REFRESH_TOKEN_INTERVAL * 1000 * 60;

        const interval = setInterval(()=>{
            if(authTokens){
                updateToken()
            }
        }, REFRESH_INTERVAL)
        return () => clearInterval(interval)

        // eslint-disable-next-line
    },[authTokens, loading])

    return(
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}