import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ClientTicketsState} from "@/stores/api/tickets.ts";

interface ValidateTicket {
    ticket_type_id: number,
    use: number,
    name: string
}

export interface CurrentClientSliceState {
    clientData: ClientTicketsState['data'] | null;
    validateTickets: ValidateTicket[];
}

const initialSliceState: CurrentClientSliceState = {
    clientData: null,
    validateTickets: []
};

const currentClientSlice = createSlice({
    name: 'currentClient',
    initialState: initialSliceState,
    reducers: {
        setCurrentClient: (state, action: PayloadAction<ClientTicketsState['data']>) => {
            state.clientData = action.payload;
        },
        clearCurrentClient: (state) => {
            state.clientData = null;
            state.validateTickets = [];
        },
        addValidateTicket: (state, action: PayloadAction<ValidateTicket>) => {

            const id = action.payload.ticket_type_id;

            if(state.validateTickets.some(ticket => ticket.ticket_type_id === id)) {
                state.validateTickets = state.validateTickets.map(ticket => {
                    if(ticket.ticket_type_id === id) {
                        return action.payload;
                    }
                    return ticket;
                });
                return;
            }

            state.validateTickets.push(action.payload);
        },
        removeValidateTicket: (state, action: PayloadAction<number>) => {
            state.validateTickets = state.validateTickets.filter(ticket => ticket.ticket_type_id !== action.payload);
        },
        setValidateTickets: (state, action: PayloadAction<ValidateTicket[]>) => {
            state.validateTickets = action.payload;
        }
    }
});

export const {
    setCurrentClient,
    clearCurrentClient,
    addValidateTicket,
    removeValidateTicket,
    setValidateTickets,
} = currentClientSlice.actions;

export default currentClientSlice.reducer;