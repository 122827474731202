import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BaseApiResponse} from "@/types";

export interface ClientTicketsState extends BaseApiResponse{
    data: {
        ticket_user: number
        identification: string
        value: string
        event_id: number
        user_data: {
            name: string
            email: string
            phone: string
            address: string
            city: string
        }
        cards: {
            event_name?: string
            event_date?: string
            quantity?: number,
            ticket_type_name: string
            ticket_type_id: number
            balance: number,
        }[]
    }
}

export interface ValidateClientTicketsResponse extends BaseApiResponse {
    data: {
        ticket_user: number
        identification: string
        value: string
        event_id: number
        cards: {
            ticket_type_id: number
            ticket_type_name: string
            balance: number
            use: number
        }[]
    }
}

export interface GetClientTicketsArgs {
    event_id: number
    identification: string,
    value: string
}

export interface ValidateTicketArgs {
    event_id: number
    ticket_user: number
    identification: string
    value: string
    cards: {ticket_type_id: number, use: number}[]
}

export const ticketsApi = createApi({
    reducerPath: 'ticketsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_API_URL,
        prepareHeaders: (headers) => {
            const Authorization = `Bearer ${localStorage.getItem('authTokens')
                ? JSON.parse(localStorage.getItem('authTokens') ?? '{}').access
                : ''}`;

            headers.set('Authorization', Authorization);
        }
    }),
    endpoints: (builder) => ({
        getClientTickets: builder.mutation<ClientTicketsState, GetClientTicketsArgs>({
            query: (args) => ({
                url: 'cards/',
                method: 'GET',
                params: args
            })
        }),
        validateTicket: builder.mutation<ValidateClientTicketsResponse, ValidateTicketArgs>({
            query: (args) => ({
                url: 'use_cards/',
                method: 'POST',
                body: args,
            })
        }),
        getAllClientTickets: builder.query<ClientTicketsState, {clientId: number}>({
            query: ({clientId, ...args}) => ({
                url: `users/${clientId}/tickets`,
                method: 'GET',
                params: args
            })
        }),
    })
});

export const {
    useGetClientTicketsMutation,
    useValidateTicketMutation,
    useGetAllClientTicketsQuery
} = ticketsApi;