import {format} from "date-fns";
import {useEffect, useState} from "react";

export default function CurrentTime() {

    const [currentTime, setCurrentTime] = useState<string>(format(new Date, 'dd/MM/yy HH:mm:ss'));

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(format(new Date, 'dd/MM/yy HH:mm:ss'));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="text-white text-sm leading-6">
            {currentTime}
        </div>
    )
}