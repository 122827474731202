import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CurrentEvent} from "@/types"

export interface CurrentEventSliceState {
    value: CurrentEvent | null;
}

const currentEventSlice = createSlice({
    name: 'currentEvent',
    initialState: {value: null} as CurrentEventSliceState,
    reducers: {
        setCurrentEvent: (state, action: PayloadAction<CurrentEvent>) => {
            state.value = action.payload;
        },
    }
});

export const {setCurrentEvent} = currentEventSlice.actions;

export default currentEventSlice.reducer;