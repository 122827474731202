import {useNedarim} from "@/hooks/useNedarim.ts";
import {useEffect, useState} from "react";

export default function ButteryStatus() {
    const {getDevicesStatus} = useNedarim();

    const [battery, setBattery] = useState<string|null>(getDevicesStatus().nedarim?.Battery ?? null);

    useEffect(() => {
        const interval = setTimeout(() => {
            setBattery(getDevicesStatus().nedarim?.Battery ?? null);
        }, 1000 * 60);
        return () => clearInterval(interval);
    }, [battery, getDevicesStatus]);

    return (
        <div className="text-white text-sm leading-6">
            {getDevicesStatus().nedarim?.Battery ?? ''}
        </div>
    )
}