import {Outlet} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "@/stores";
import Footer from "@/layout/Footer.tsx";
import {cn} from "@/lib/utils.ts";
import {useNedarim} from "@/hooks/useNedarim.ts";


export default function Layout() {

    const {isActive} = useNedarim();

    return(
        <Provider store={store}>
            <main className="flex flex-col min-h-screen bg-light-blue">
                <div className={cn("flex flex-col flex-grow", {
                    "pb-[50vh]": isActive,
                })}>
                    <div className="flex-grow">
                        <Outlet/>
                    </div>
                </div>
                <div className="mt-auto">
                    <Footer/>
                </div>
            </main>
        </Provider>
)
}