
interface DeviceStatus {
    nedarim: {
        Battery: string;
        Signal: string;
        WifiSignal: string;
        Version: string;
        SIM: string;
        POS_Brand: string;
        YSDK_Version: string;
        Firmware: string;
        SignType: string;
    } | null;
    userAgent: string;
    functions: string[];
}

export function useNedarim() {

    const isActive = window.PosNedarim !== undefined;

    function vibrate() {
        isActive && window.PosNedarim?.Vibrate();
    }

    function reload() {
        if(isActive) {
            window.PosNedarim?.Reload()
            vibrate();
        }

    }

    function openRFID() {
        isActive && window.PosNedarim?.OpenRFID();
    }

    function openPOS() {
        isActive && window.PosNedarim?.OpenPOS();
    }

    function getDevicesStatus(): DeviceStatus {
        const np = isActive ? window.PosNedarim?.GetDevicesStatus() : null;
        const navigationUser = window.navigator?.userAgent ?? {};

        return {
            nedarim: typeof np === 'string' ? JSON.parse(np) : np,
            userAgent: navigationUser,
            functions: isActive ? Object.getOwnPropertyNames(window.PosNedarim) : []
        }
    }

    function updateApk() {
        window.PosNedarim?.UpdateApk();
    }

    return {
        isActive,
        vibrate,
        reload,
        openRFID,
        openPOS,
        getDevicesStatus,
        updateApk
    }
}