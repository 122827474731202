import {Button} from "@/components/ui/button.tsx";
import {ReactNode, useContext} from "react";
import AuthContext from "@/context/AuthContext.tsx";
import {ArrowRotateLeft, ArrowUp, Devices, Setting4} from "iconsax-react";
import {useNedarim} from "@/hooks/useNedarim.ts";
import {Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger} from "@/components/ui/sheet.tsx";
import {Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger} from "@/components/ui/dialog.tsx";
import collect from "collect.js";


export default function Footer() {

    const {logoutUser} = useContext(AuthContext)

    return (
        <footer className="bg-light-blue py-4">
            <div className="flex flex-col gap-2 justify-center items-center">
                <div className="flex gap-2">
                    <Settings/>
                    <Button
                        onClick={logoutUser}
                        variant="outline"
                        size="sm"
                        className="bg-slate-300 block"
                    >
                        התנתקות
                    </Button>
                </div>
                <p className="text-slate-400 text-sm">© כל הזכויות שמורות - ידע פון 2024</p>
            </div>
        </footer>
    )
}

function Settings() {

    const nedarim = useNedarim();

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    variant="outline"
                    size="icon-sm"
                    className="bg-slate-300 flex justify-center items-center"
                >
                    <Setting4 className="h-4"/>
                </Button>
            </SheetTrigger>
            <SheetContent className="bg-white">
                <SheetHeader>
                    <SheetTitle>הגדרות</SheetTitle>
                </SheetHeader>

                <div className="mt-10">
                    <Button
                        className="w-full gap-2 justify-start bg-transparent" variant="ghost"
                        onClick={nedarim.reload}
                    >
                        <ArrowRotateLeft className="h-4"/>
                        ריענון
                    </Button>

                    <Button
                        className="w-full gap-2 justify-start bg-transparent" variant="ghost"
                        onClick={nedarim.updateApk}
                    >
                        <ArrowUp className="h-4"/>
                        עדכון אפליקצייה
                    </Button>

                    <Dialog>
                        <DialogTrigger asChild>
                            <Button
                                className="w-full gap-2 justify-start bg-transparent" variant="ghost"
                            >
                                <Devices className="h-4"/>
                                מצב התקן
                            </Button>
                        </DialogTrigger>
                        <DialogContent className="bg-white max-h-screen w-screen overflow-auto">
                            <DialogHeader>
                                <DialogTitle>מצב התקן</DialogTitle>
                            </DialogHeader>
                            <div className="p-4 max-w-screen">
                                {collect(nedarim.getDevicesStatus()).map((value, key) => (
                                    <div key={key} className="mt-4">
                                        <h4 className="text-xs font-bold">{key}</h4>
                                        <div className="text-xs">
                                            {!!value && (
                                                typeof value === 'object' ? <div>
                                                    {collect(value as Record<string, string>|string[]).map((v, k) => (
                                                        <div key={k} className="">
                                                            <div><span className="font-bold">{k}:</span> {v}</div>
                                                        </div>
                                                    )).values<ReactNode[]>().all()}
                                                </div>: <div>{typeof value === 'string' && value}</div>
                                            )}
                                        </div>
                                    </div>
                                )).values<ReactNode[]>().all()}
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </SheetContent>
        </Sheet>

    )
}