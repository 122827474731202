import Logo from '@/assets/yeda-phone-logo.svg?react';
import {Input} from "@/components/ui/input.tsx";
import {Button} from "@/components/ui/button.tsx";
import {ChangeEvent, FormEvent, useContext, useState} from "react";
import AuthContext from "@/context/AuthContext.tsx";
import {cn} from "@/lib/utils.ts";
import {useNedarim} from "@/hooks/useNedarim.ts";

export default function Login() {

    const {
        loginUser,
        isLoginProcess,
        error,
    } = useContext(AuthContext);

    const [{username, password}, setCredentials] = useState({
        username: '',
        password: '',
    });

    function handleLogin(e: FormEvent) {
        e.preventDefault();
        loginUser({username, password});
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setCredentials((v) => ({...v, [e.target.name]: e.target.value}));
    }

    const {isActive} = useNedarim();

    return (
        <div className="bg-light-blue flex flex-col justify-center items-center min-h-screen">
            <div>
                <Logo className="w-32 h-32"/>
            </div>
            <div className="text-dark-blue mt-10">
                <h1 className="text-2xl font-bold text-center">
                    כרטיס-פון מבית ידע פון
                </h1>
                <h4 className="text-center font-light">
                    ממשק בקרת כניסה לאירועים
                </h4>
            </div>
            <form className={cn("flex flex-col space-y-4 mt-8", {
                "mb-[50vh]": isActive,
            })} onSubmit={handleLogin}>
                <Input autoFocus value={username} name="username" onChange={handleChange} type="text" placeholder="שם משתמש"/>
                <Input type="password" value={password} name="password" onChange={handleChange} placeholder="סיסמא"/>
                {error && <div className="text-red-500 text-center text-sm">{error}</div>}
                <div className="flex justify-center">
                    <Button
                        size="sm"
                        type="submit"
                        className="px-8 font-semibold"
                        disabled={isLoginProcess}
                    >
                        {isLoginProcess ? 'מתחבר...' : 'כניסה'}
                    </Button>
                </div>
            </form>
        </div>
    )
}