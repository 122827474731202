import {Button} from "@/components/ui/button.tsx";
import {useSelector} from "react-redux";
import {RootState} from "@/stores";
import {Link, useLocation, useNavigate} from "react-router-dom";
import CurrentTime from "@/components/layout/current-time.tsx";
import ButteryStatus from "@/components/layout/buttery-status.tsx";


export default function Header() {

    const currentEvent = useSelector((state: RootState) => state.currentEvent.value);

    const currentPath = useLocation().pathname;

    const navogate = useNavigate();

    return (
        <header className="bg-dark-blue border-b-[0.5rem] border-yellow">
            <div className="bg-black px-2 flex justify-between items-center">
                <CurrentTime/>
                <ButteryStatus/>
            </div>
            <div className="flex p-4 px-6 justify-between ">
                <div className="text-white text-base text-start">
                    {currentEvent ? (
                        <>
                            <div className="font-bold whitespace-nowrap">{currentEvent?.parentType?.name}</div>
                            <div>תחילת מופע: {currentEvent?.event_time}</div>
                        </>
                    ) : (
                        <div>אירוע לא נבחר</div>
                    )}
                </div>
                <div>
                    {currentPath === '/events' ? (
                        (currentEvent ? (
                            <Button
                                    variant="secondary"
                                    size="sm"
                                    className="rounded-full px-8"
                                    onClick={() => navogate(-1)}
                                >
                                    חזרה
                                </Button>
                            ) : null)
                    ) : (
                        <Button asChild variant="secondary" size="sm" className="rounded-full px-8">
                            <Link to="/events">
                                החלף אירוע
                            </Link>
                        </Button>
                    )}
                </div>
            </div>
        </header>
    )
}