import Header from "@/layout/Header.tsx";
import {CurrentEvent, EventType, Event} from "@/types";
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "@/components/ui/accordion.tsx";
import collect from "collect.js";
import {useState} from "react";
import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle} from "@/components/ui/dialog.tsx";
import {Button} from "@/components/ui/button.tsx";
import {useDispatch} from "react-redux";
import {setCurrentEvent} from "@/stores/currentEventSlice.ts";
import {useNavigate} from "react-router-dom";
import {useGetEventsQuery} from "@/stores/api/events.ts";

export default function Events() {

    const [replaceEvent, setReplaceEvent] = useState<CurrentEvent | null>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data} = useGetEventsQuery();
    function pickEvent(event: Event, parentType: EventType) {
        setReplaceEvent({
            ...event,
            parentType
        });
    }

    function confirmEvent() {
        if (replaceEvent) {
            dispatch(setCurrentEvent(replaceEvent));
            setReplaceEvent(null);
            navigate('/identification');
        }
    }

    return (
        <>
            <Header/>
            <div className="bg-white">
                <Accordion type="single" collapsible>
                    {data && data.data.map((eventType) => (
                        <AccordionItem value={String(eventType.id)} key={eventType.id}>
                            <AccordionTrigger className="px-6 text-2xl font-bold">{eventType.name}</AccordionTrigger>
                            <AccordionContent>
                                <div className="text-lg sm:text-xl ">
                                    {eventType.events.map((event) => (
                                        <button onClick={() => pickEvent(event, eventType)} key={event.id} className="px-10 w-full flex gap-4 py-4 transition-all hover:bg-light-blue border-b border-b-dark-blue last:border-b-0">
                                            <span className="block bg-dark-blue px-2 rounded-xl font-bold text-white">
                                                {event.id}
                                            </span>
                                            <div>{[event.event_time].filter(v => v).join(' - ')}</div>
                                            <div className="font-bold text-sm">{event.name}</div>
                                            <div className="text-gray-500">
                                                {collect(event.categories).pluck('name').join(' / ')}
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>

            <Dialog open={!!replaceEvent} onOpenChange={() => setReplaceEvent(null)}>
                <DialogContent className="h-[calc(100vh-20px)] w-[calc(100vw-20px)] max-w-full bg-white">
                    <DialogHeader>
                        <DialogTitle>החלפת אירוע</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                        <div className="text-xl text-dark-blue text-center flex flex-col gap-2">
                            <div>אנו מחליפים לאירוע הבא</div>

                            <div className="flex justify-center mt-4">
                                <div
                                    className="block max-w-min bg-dark-blue px-4 rounded-xl font-bold text-white">
                                    {replaceEvent?.id}
                                </div>
                            </div>
                            <div className="text-4xl font-bold">{replaceEvent?.parentType.name}</div>
                            <div>{[replaceEvent?.event_time].filter(v => v).join(' - ')}</div>
                            <div className="text-gray-500">
                                {collect(replaceEvent?.categories).pluck('name').join(' / ')}
                            </div>
                            <div className="mt-10">
                                <Button
                                    variant="default"
                                    size="lg"
                                    className="font-bold text-xl rounded-full px-8"
                                    onClick={() => confirmEvent()}
                                >
                                    אישור
                                </Button>
                            </div>
                        </div>
                    </DialogDescription>
                </DialogContent>
            </Dialog>
        </>
    )
}