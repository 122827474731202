import Header from "@/layout/Header.tsx";
import Image from "@/assets/summary_texture.svg?react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@/stores";
import collect from "collect.js";
import {Button} from "@/components/ui/button.tsx";
import {clearCurrentClient} from "@/stores/currentClientSlice.ts";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";


export default function Summary() {

    const {validateTickets, clientData} = useSelector((state: RootState) => state.currentClient);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleEnd = () => {
        dispatch(clearCurrentClient());
    }

    useEffect(() => {
        if(!clientData) {
            navigate('/identification');
        }
    }, [clientData, navigate]);

    return (
        <>
            <Header/>

            <div className="mt-10 w-[95vw] max-w-md mx-auto items-center">
                <div className="flex justify-center w-full">
                    <Image className="h-40 origin-top-right"/>
                </div>

                <div className="mt-10">
                    <h1 className="text-2xl font-bold text-center mt-10">
                        {collect(validateTickets).sum('use')} כרטיסים נבחרו
                    </h1>

                    <div className="mt-12 px-4">
                        <table className="max-w-96 w-full mx-auto bg-white rounded-xl overflow-hidden shadow-xl">
                            <thead>
                            <tr className="text-lg font-bold text-start border-b border-gray-300">
                                <th className="text-start p-2">סוג כרטיס</th>
                                <th className="text-start p-2">שימוש</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                validateTickets.map(ticket => (
                                    <tr key={ticket.ticket_type_id} className="text-xl border-b last:border-b-0 border-gray-300">
                                        <td className="p-2">{ticket.name}</td>
                                        <td className="p-2">{ticket.use}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mt-16 w-full px-20">
                    <Button
                        onClick={handleEnd}
                        className="block w-full h-16 text-2xl font-bold border border-black"
                    >
                        סיום
                    </Button>
                </div>
            </div>
        </>
    )
}