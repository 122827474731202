import Header from "@/layout/Header.tsx";
import React, {
    JSX,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {IdentificationOption} from "@/types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@/stores";
import {useNavigate} from "react-router-dom";
import collect from "collect.js";
import {Button} from "@/components/ui/button.tsx";
import {useGetClientTicketsMutation} from "@/stores/api/tickets.ts";
import {
    ArrowCircleLeft2,
    Barcode,
    Call,
    Icon,
    MoreCircle,
    ShoppingCart,
    UserSquare
} from "iconsax-react";
import {setCurrentClient} from "@/stores/currentClientSlice.ts";

type ListIdentificationsElements = {
    barcode: JSX.Element;
    tz: JSX.Element;
    phone: JSX.Element;
    sale_id: JSX.Element;
}

interface IdentificationItem {
    Element: JSX.ElementType;
    props: {
        children: string;
        type: IdentificationOption;
        Icon: React.ComponentType<never>;
    }
}

export default function Identification() {

    const currentEvent = useSelector((state: RootState) => state.currentEvent.value);

    const navigate = useNavigate();

    const [currentIdentification, setCurrentIdentification] = useState<IdentificationOption|null>(null);
    const [error, setError] = useState<string|null>(null);
    const [getClientTickets, {isLoading: isLoadingClientTickets}] = useGetClientTicketsMutation();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!currentEvent?.id) {
            navigate('/events');
        }
    }, [currentEvent, navigate]);

    const [identificationList, defaultIdentification] = useMemo(() => [
        currentEvent?.identity_options ?? currentEvent?.parentType?.identity_options ?? [],
        currentEvent?.default_identity ?? currentEvent?.parentType?.default_identity,
    ], [currentEvent]);

    const inputRef = useRef<HTMLInputElement>(null);


    const list = useMemo(() => collect<IdentificationItem>({
        barcode: {
            Element: Input,
            props: {
                type: 'barcode',
                children: 'סריקת ברקוד',
                Icon: Barcode
            }
        },
        tz: {
            Element: Input,
            props: {
                type: 'tz',
                children: 'מס\' זהות',
                Icon: UserSquare
            }
        },
        phone: {
            Element: Input,
            props: {
                type: 'phone',
                children: 'טלפון',
                Icon: Call
            }
        },
        order_code: {
            Element: Input,
            props: {
                type: 'order_code',
                children: 'קוד הזמנה',
                Icon: ShoppingCart
            }
        }
    }), []);

    const inputs: IdentificationItem[] = useMemo(() => {

        let result = list;

        if((identificationList ?? []).length > 0) {
            // @ts-expect-error The first parameter is not needed
            result = result.filter((_, key: keyof ListIdentificationsElements) => !!identificationList?.includes(key as never));
        }

        const firstItems: IdentificationItem[] = [];

        const defaultItem = defaultIdentification
            ? result.get<IdentificationOption, IdentificationItem>(defaultIdentification)
            : null;

        if(defaultItem) {
            firstItems.push(defaultItem);
            result = result.forget(defaultIdentification);
        }

        const newCurrentIdentification = currentIdentification ?? defaultIdentification ?? identificationList?.[0] ?? null;

        if(newCurrentIdentification !== currentIdentification) {
            setCurrentIdentification(newCurrentIdentification);
        }

        return [...firstItems, ...result.values<IdentificationItem>().all()];

    }, [list, identificationList, defaultIdentification, currentIdentification])

    function handleCheck(nfcData?: string) {

        if(!currentIdentification || ! inputRef.current || ! currentEvent?.id) {
            return;
        }

        if(! nfcData ) {
            switch (currentIdentification) {
                // case 'tz':
                //     if (!validateIsraeliIdNumber(inputRef.current.value)) {
                //         setError('מספר תעודת הזהות אינו תקין');
                //         return;
                //     }
                //     break;
                case 'phone':
                    if (!validateIsraelNumberPhone(inputRef.current.value)) {
                        setError('מספר הטלפון אינו תקין');
                        return;
                    }
                    break;
            }
        }

        const data = {
            event_id: currentEvent.id,
            identification: nfcData ? 'nfc' : currentIdentification,
            value: nfcData ?? inputRef.current.value
        };

        getClientTickets(data).unwrap()
            .then((res) => {
                dispatch(setCurrentClient(res.data));
                navigate('/validation');
            })
            .catch((err) => {
                setTimeout(() => inputRef.current?.focus(), 100);
                setError(err.data.error_msg);

                if(window.PosNedarim) {
                    window.PosNedarim.Vibrate();
                    window.PosNedarim.showToast(err.data.error_msg);
                    setTimeout(() => window.PosNedarim && window.PosNedarim.OpenRFID(), 1000)
                }
            })

    }

    // function validateIsraeliIdNumber(id: string) {
    //     id = String(id).trim();
    //
    //     if (id.length > 9) {
    //         return false;
    //     }
    //     const idNumber = id.length < 9 ? id.padStart(9, '0') : id;
    //     return Array.from(idNumber, Number).reduce((counter, digit, i) => {
    //         const step = digit * ((i % 2) + 1);
    //         return counter + (step > 9 ? step - 9 : step);
    //     }) % 10 === 0;
    // }

    function validateIsraelNumberPhone(phone: string) {
        return /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/.test(phone);
    }

    useEffect(() => {

        if(window.PosNedarim) {
            window.PosNedarim.Vibrate();
            window.PosNedarim?.showToast('זוהה חיבור למסופון נדרים');

            window.POSAction = (action, ...data) => {

                /*
                action options
                1. AllowRFID
                2. DoneRFID
                 */

                if(window.PosNedarim) {

                    switch (action) {
                        case 'AllowRFID':
                            window.PosNedarim.Vibrate();
                            window.PosNedarim.showToast('נא להקריא כרטיס');
                            break;

                        case 'DoneRFID':
                            window.PosNedarim.Vibrate();
                            window.PosNedarim.showToast('קיבלנו, בודק...');
                            handleCheck(data?.[1]);
                            break;
                    }
                }
            }

            window.PosNedarim.OpenRFID();
        }
    }, []);


    return (
        <>
            <Header/>
            <div className="px-4 w-full md:max-w-md mx-auto">
                <div className="flex justify-center text-xl font-bold mt-12">
                    הצג / העבר כרטיס או
                </div>

                <div className="mt-12 flex flex-col justify-center">
                    <div
                       className="bg-white flex gap-4 pe-2 ps-4 shadow-lg relative font-bold shadow-gray-300/60 border border-black rounded-xl py-2 text-center"
                    >
                        <label className="absolute -top-4 bg-white px-2 rounded-lg">
                            <span className="text-dark-blue/80">
                                {list.get(currentIdentification)?.props.children}
                            </span>
                        </label>
                        <input
                            type="tel"
                            autoFocus
                            ref={inputRef}
                            disabled={isLoadingClientTickets}
                            className="w-full outline-none text-xl placeholder:text-gray-300 disabled:opacity-30"
                            placeholder={'הזן ' + list.get(currentIdentification)?.props.children}
                            onKeyDown={e => e.key === 'Enter' && handleCheck()}
                            onBlur={(e) => e.currentTarget.focus()}
                        />

                        <div>
                            <Button
                                size="icon"
                                className="p-0 flex text-yellow"
                                variant="ghost"
                                onClick={() => handleCheck()}
                            >
                                {isLoadingClientTickets
                                    ? <MoreCircle variant="Bold" className="animate-pulse w-8 h-8"/>
                                    : <ArrowCircleLeft2 variant="Bold" className="w-8 h-8"/>
                                }

                            </Button>
                        </div>
                    </div>

                    {error &&  (
                        <div className="text-red-600 text-center mt-4">
                            {error}
                        </div>
                    )}
                </div>

                <div className="flex gap-4 mt-8 justify-center">
                    {inputs.map(({Element, props}) => (
                        <div key={props.type}>
                        <Element
                                {...props}
                                isActive={currentIdentification === props.type}
                                setActive={() => setCurrentIdentification(props.type)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

type InputProps = {
    children: string;
    type: IdentificationOption;
    isActive?: boolean;
    setActive: () => void;
    Icon?: Icon
}

function Input(props: InputProps) {

    const {children, Icon, isActive, setActive} = props;

    return (
        <>
            <div className="w-24 text-center flex justify-center flex-col items-center">
                <button
                    data-active={isActive}
                    className="bg-transparent p-0 w-12 flex justify-center items-center h-12 border border-transparent data-[active=true]:border-dark-blue rounded-xl data-[active=true]:bg-dark-blue/20"
                    onClick={setActive}
                >
                    {Icon &&
                        <Icon
                            data-active={isActive}
                            variant="Bulk"
                            className="w-10 h-10 text-dark-blue/25 hover:text-dark-blue/80 transition-all data-[active=true]:text-dark-blue/100"
                        />
                    }
                </button>

                <div>
                    {children}
                </div>
            </div>
        </>
    )
}