import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {EventType} from "@/types";

export interface EventsApiState {
    data: EventType[]
    error_msg: string
    status: "OK" | "ERROR"
    status_code: number
}

export const eventsApi = createApi({
    reducerPath: 'eventsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_API_URL,
        prepareHeaders: (headers) => {
            const Authorization = `Bearer ${localStorage.getItem('authTokens')
                ? JSON.parse(localStorage.getItem('authTokens') ?? '{}').access
                : ''}`;

            headers.set('Authorization', Authorization);
        }
    }),
    endpoints: (builder) => ({
        getEvents: builder.query<EventsApiState, void>({
            query: () => 'events/'
        }),
    })
});

export const {
    useGetEventsQuery,
} = eventsApi;