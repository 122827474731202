import './App.css'
import {Button} from "@/components/ui/button.tsx";
import {Link} from "react-router-dom";

function App() {
  return (
    <div dir="rtl" className="lg:px-0 px-16 min-h-screen flex justify-center items-center flex-col bg-slate-100">
        <div className="relative">
            <h1 className="text-7xl lg:text-9xl drop-shadow-2xl text-center">
                בקרוב יהיה כאן שמח :)
            </h1>

            <div className="absolute -top-4 -start-4">
                <span className="absolute opacity-30 -top-1/2 -end-1/2 w-20 h-20 bg-[#0283C0] rounded-full animate-ping"/>
                <span className="block z-10 w-10 h-10 bg-[#0283C0] rounded-full"/>
            </div>
        </div>
        <h3 className="text-4xl text-slate-400 mt-20 text-center">
            ידע פון - חזקים בתקשורת
        </h3>

        <div className="mt-12 flex flex-col gap-2">
            <div>רשימת עמודים</div>
            <div className="flex flex-wrap gap-2 justify-center">
                <Button asChild><Link to="/login">עמוד כניסה</Link></Button>
                <Button asChild><Link to="/events">עמוד בחירת אירוע</Link></Button>
                <Button asChild><Link to="/identification">עמוד זיהוי לקוח</Link></Button>
                <Button asChild><Link to="/validation">עמוד תיקוף</Link></Button>
                <Button asChild><Link to="/summary">עמוד סיכום תיקוף</Link></Button>
            </div>
        </div>
    </div>
  )
}

export default App
