import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "@/Pages/Auth/Login.tsx";
import App from "@/App.tsx";
import Events from "@/Pages/Events.tsx";
import Layout from "@/layout/Layout.tsx";
import Identification from "@/Pages/Identification.tsx";
import Summary from "@/Pages/Summary.tsx";
import Validate from "@/Pages/Validate.tsx";
import {AuthProvider} from "@/context/AuthContext.tsx";
import {store} from "@/stores/api/authStore";
import {Provider} from "react-redux";

export default function RouterComponent() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={<Layout/>}>
                            <Route index element={<App/>}/>
                            <Route path="/events" element={<Events/>}/>
                            <Route path="/identification" element={<Identification/>}/>
                            <Route path="/validation" element={<Validate/>}/>
                            <Route path="/summary" element={<Summary/>}/>
                        </Route>

                        <Route path="/login" element={<Login/>}/>

                        {/*<Route path="/" element={<AppLayout/>}>*/}
                        {/*    <Route index element={<OverviewPage/>}/>*/}
                        {/*    <Route path="/actions" element={<ActionsCompany/>}/>*/}
                        {/*    <Route path="/analitsys" element={<ParticipantAnalysis/>}/>*/}
                        {/*    <Route path="*" element={<NotFoundPage/>}/>*/}
                        {/*</Route>*/}
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </Provider>
    )
}