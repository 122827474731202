import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './global.css'
import RouterComponent from "@/RouterComponent.tsx";

// import * as Sentry from "@sentry/react";

// Sentry.init({
//     dsn: "https://80ed95f05720ce661169d6e4d09f53de@o4507775069061120.ingest.us.sentry.io/4507775610978304",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });



ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterComponent/>
  </React.StrictMode>,
)
