import {configureStore} from '@reduxjs/toolkit'
// import {setupListeners} from "@reduxjs/toolkit/query";
import currentEventSlice from "@/stores/currentEventSlice.ts";
import currentClientSlice from "@/stores/currentClientSlice.ts";
import {eventsApi} from "@/stores/api/events.ts";
import {ticketsApi} from "@/stores/api/tickets.ts";

export const store = configureStore({
    reducer: {
        currentEvent: currentEventSlice,
        currentClient: currentClientSlice,
        [eventsApi.reducerPath]: eventsApi.reducer,
        [ticketsApi.reducerPath]: ticketsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            eventsApi.middleware,
            ticketsApi.middleware
        )
});

// setupListeners(store.dispatch);


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch